import { useHistory } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { usePrevious } from 'webrix/hooks';
import sizeMe from 'react-sizeme';

import { SOFTPHONE_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import { SOFTPHONE_LANDING_ROUTE } from 'lib/common/constants/softphoneDefaultNavOptions';
import { useContactContext } from 'lib/common/contexts/ContactContext';

import SoftphoneNavMenuItem from '../SoftphoneNavMenuItem';
import TasksPopover from './components/TasksPopover';

import './softphone-task-list.scss';
import { SoftPhoneTaskItem } from './components/SoftPhoneTaskItem/SoftPhoneTaskItem';

const TASK_LIST_MINIMUM_HEIGHT = 90;
const TASK_HEIGHT = 40;
const MINIMUM_VISIBLE_TASKS = 1;

const sizeMeHOC = sizeMe({ monitorHeight: true, refreshRate: 16 });

interface ISoftphoneTaskListProps {
  isCreateTaskDisabled: boolean;
  isReversedLayout: boolean;
  size: { height: number };
}

const SoftphoneTaskList = ({ isCreateTaskDisabled, isReversedLayout, size: { height } }: ISoftphoneTaskListProps) => {
  const history = useHistory();
  const [visibleTasks, setVisibleTasks] = useState<number>(0);

  const {
    actions: { setSelectedTaskId },
    state: { tasks, selectedTaskId }
  } = useContactContext();

  useEffect(() => {
    const availableTasksSpace = Math.floor((height - TASK_LIST_MINIMUM_HEIGHT) / TASK_HEIGHT);
    const numberOfVisibleTasks = availableTasksSpace + MINIMUM_VISIBLE_TASKS;

    if (!Boolean(tasks.length)) {
      return;
    }

    // avoid showing +1 icon as we show the actual task instead.
    if (tasks?.length - numberOfVisibleTasks <= 1) {
      return setVisibleTasks(tasks.length);
    }

    setVisibleTasks(numberOfVisibleTasks);
  }, [tasks, height]);

  const lastSelectedTaskId = usePrevious(selectedTaskId);

  const pushToTask = () => {
    if (history.location.pathname === SOFTPHONE_PAGE_ROUTES.TASK) {
      return history.replace(SOFTPHONE_PAGE_ROUTES.TASK);
    }

    history.push(SOFTPHONE_PAGE_ROUTES.TASK);
  };

  const handleTaskClick = (taskId: string) => {
    setSelectedTaskId(taskId);
    pushToTask();
  };

  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);
  const incomingTask = tasks.find(
    (task) => task.status === CONTACT_STATES.CONNECTING && task.type !== CONTACT_TYPES.CONFERENCE_CALL
  );

  useEffect(() => {
    if (lastSelectedTaskId || !selectedTaskId) {
      return;
    }

    pushToTask();
  }, [selectedTaskId]);

  useEffect(() => {
    const taskToSelect = incomingTask || tasks[0];

    setSelectedTaskId(taskToSelect?.taskId);

    pushToTask();
  }, [tasks?.length]);

  return (
    <div className="softphone-task-list">
      {!isCreateTaskDisabled && <hr className="softphone-task-list__separator" />}
      {!Boolean(tasks.length) && (
        <SoftphoneNavMenuItem
          key={SOFTPHONE_LANDING_ROUTE.link}
          link={SOFTPHONE_LANDING_ROUTE.link!}
          name={SOFTPHONE_LANDING_ROUTE.name}
          icon={SOFTPHONE_LANDING_ROUTE.icon}
        />
      )}
      {tasks.slice(0, visibleTasks).map((task) => (
        <SoftPhoneTaskItem
          key={task.taskId}
          isActiveTask={task.taskId === selectedTaskId}
          task={task}
          incomingTask={incomingTask}
          handleTaskClick={handleTaskClick}
          highlightPopover={false}
        />
      ))}
      <TasksPopover
        tasks={tasks.slice(visibleTasks)}
        selectedTask={selectedTask}
        isReversedLayout={isReversedLayout}
        onTaskClick={handleTaskClick}
      />
    </div>
  );
};

export default sizeMeHOC(SoftphoneTaskList);
